import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useLanguage } from '../LanguageContext';

import { formatMoney, disconnect, setItemSession, getItemSession } from '../utils';
import {
    fetchProductsById,
    fetchProductsBySuggestion,
    fetchBanners,
    fetchProductsRelated
} from '../services/products-api';
import { Loader } from '../components/Loader/Loader';
import ProductCard from '../components/Product/ProductCard';
import { arrOrigin } from '../constants';
import { addToCart, fetchCartItems, updateCart } from '../services/cart-api';
import * as CartActions from '../store/actions/cart'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Slider from "react-slick";
import { connect } from 'react-redux';
import Magnifier from "react-magnifier";
import Cookies from 'js-cookie';
import ReactGA from 'react-ga'
import { registrarAviseMe, validarAviseMe } from '../services/contact-api';
import Modal from "react-bootstrap/Modal";
import { FaTimes } from 'react-icons/fa';
import { ConsoleView } from 'react-device-detect';
const Swal = require('sweetalert2');
const Product = ({ match, origin, refreshCartFn }) => {

    Cookies.remove('LinkProduto');
    useEffect(() => { ReactGA.pageview(window.location.pathname + window.location.search) }, [])
    const [isLoading, setLoading] = useState(true);
    const [isLoadingSuggestion, setLoadingSuggestion] = useState(true);
    const [product, setProduct] = useState('');
    const [productsSuggestion, setProdutosSuggestion] = useState([]);
    const [adding, setAdding] = useState(false);
    const [banners, setBanners] = useState([]);
    const [selecteSize, setSelectedSize] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [colors, setColors] = useState(['']);
    const [bordaAtiva, setBordaAtiva] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedDescricao, setSelectedDescricao] = useState(null);
    const [selectedCodigo, setSelectedCodigo] = useState(null);
    const [colorsSelect, setColorsSelect] = useState('');
    const [sizes, setSizes] = useState([]);
    const [idProduto, setIdProduto] = useState(match.params.id);
    const [produtosAgrupados, setProdutosAgrupados] = useState([]);
    const [isLoadingBanner, setIsLoadingBanner] = useState(false);
    const [email, setEmail] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    //Modal
    const showModal = () => {
        validarAviseMe()
            .then(resultAv => {
                var l = resultAv.data.Data.Dados
                if (l[0] !== null) {
                    setEmail(l[0].Email)
                    setIsOpen(true)
                }


            }).catch(reject => {
                setIsOpen(true)
            })


    };
    const hideModal = () => {
        setIsOpen(false);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);

    }
    const handleEmailSubmit = (e) => {
        e.preventDefault();

        let validEmailRe = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        if (validEmailRe.test(email)) {
            registrarAviseMe(product.idProduto, email)
                .then(() => {
                    alert('¡Correo electrónico registrado correctamente! ¡Se le notificará por ' + email + ' a partir de ahora!');
                    hideModal();
                })
                .catch(() => {
                    alert('Se produjo un error al enviar el mensaje. ¡Inténtalo de nuevo!');
                });
        } else {
            alert('¡Introduzca un correo electrónico válido!')
        }
    }
    //Fim Modal

    const { translations, changeLanguage } = useLanguage();

    // Props
    const history = useHistory();
    const login = JSON.parse(getItemSession('_dados'));

    const [quantity, setQuantity] = useState(1)

    const decrement = () => setQuantity(q => isNaN(q) || q < 2 ? 1 : q - 1);
    const increment = () => setQuantity(q => q >= 0 ? q + 1 : 1);

    const handleChange = (e) => {
        if (parseInt(e.target.value) > 0) {
            setQuantity(parseInt(e.target.value));
        } else {
            setQuantity();
        }
    }

    // Load Data


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchProductsById(idProduto);
                const dados = result.data.Data.Dados;
                setProduct(dados[0])

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        fetchRelatedProductsData()
    }, [idProduto]);

    useEffect(() => {
        fetchProductsBySuggestion(4)
            .then(result => {
                setProdutosSuggestion(result.data.Data.Dados)
            })
            .then(result => setLoadingSuggestion(false))
            .catch(reject => {
                disconnect();
            })
    }, [])

    //Carregamendo do banner do produto
    useEffect(() => {
        fetchBanners(idProduto)
            .then(result => {
                setBanners(result?.data.Data.Dados)
            })
            .catch(reject => {
                //disconnect();
            })
    }, [idProduto]);



    const HaveLink = ({ banner }) => {
        if (banner?.Sequencia > 0) {
            return (
                <Magnifier
                    src={banner.Link}
                    width={300}
                    mgWidth={180}
                    mgHeight={180}
                    zoomFactor={1}
                />
            )
        }

        return <img src={banner.Link} alt="" style={{ margin: 'auto' }} />
    }

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button
                className={`btn btn--icon btn-left ${className}`}
                onClick={onClick}
                style={{ ...style }}
            >
                <FaChevronLeft size={14} color="#000" />
            </button>
        )
    }
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button
                className={`btn btn--icon btn-right ${className}`}
                onClick={onClick}
                style={{ ...style }}
            >

                <FaChevronRight size={14} color="#000" />
            </button>
        )
    }


    function customPaging(i) {
        return <span><img src={banners[i].Link} alt="" style={{ margin: 'auto', width: '100px', height: '100' }} /></span>;
    }


    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        pauseOnHover: true,
        customPaging: customPaging,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        loop: true,
        arrows: false,
        prevArrow: false,
        nextArrow: false
    };

    const handleAddToCart = (e) => {

        if (sizes.length > 0 || produtosAgrupados.length > 0) {
            if (selecteSize == null || selecteSize == undefined || selectedColor == null || selectedColor == undefined) {
                alert('Es necesario seleccionar color y talla.');
                return;
            }
        }
        e.preventDefault();
        document.getElementById("botaoRoxoPrincipal").setAttribute("disabled", "disabled");;
        const intQuantity = parseInt(quantity);

        if (isNaN(intQuantity) || intQuantity < 1) {
            alert(translations.ProductCard?.Alerta_qtd_Valida);
            return false;
        }

        setAdding(true);

        buttonActionDisabled(e);

        const get = getItemSession('_carrinho');
        const cartItems = JSON.parse(get);

        const item = cartItems.Dados.filter(item => { return item.idProduto === product.idProduto })

        let time = 2000;

        if (item.length > 0) {
            const newQuantity = intQuantity + parseInt(item[0].Quantidade)

            updateCart(product.idProduto, item[0].Item, newQuantity)
                .then(async resultUpdate => {
                    if (resultUpdate.data.Codigo === 500) {
                        alert(resultUpdate.data.Status);
                        setAdding(false);
                        time = 0;
                    } else {
                        await fetchCartItems()
                            .then(result => {
                                if (result.data.Data.Dados.length > 0) {
                                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                    refreshCartFn(result.data.Data)
                                }
                                setAdding(false);
                            })
                            .catch(reject => {
                                disconnect();
                            })

                    }
                })
                .catch(reject => {
                    disconnect();
                })

        } else {
            addToCart(product.idProduto, intQuantity, arrOrigin['pagina_produto'])
                .then(resultAdd => {
                    if (resultAdd.data.Codigo === 500) {
                        if (resultAdd.data.CodigoInterno === 4) {
                            disconnect()
                        } else {
                            alert(resultAdd.data.Status);
                            setAdding(false);
                            time = 0;
                        }
                    } else {
                        fetchCartItems()
                            .then(result => {
                                if (result.data.Data.Dados.length > 0) {
                                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                    refreshCartFn(result.data.Data)
                                }
                                setAdding(false);
                            })
                            .catch(reject => {
                                disconnect();
                            })
                    }
                })
                .catch(reject => {
                    disconnect();
                })
        }


        buttonActionEnabled(e);
    }

    const buttonActionDisabled = (e) => {
        e.target.disabled = true;
    }
    const buttonActionEnabled = (e) => {
        e.target.disabled = false;
    }

    const fetchRelatedProductsData = async () => {
        const productsRelated = await fetchProductsRelated(idProduto);

        const products = productsRelated.data.Data.Dados;
        const groupedProducts = products.reduce((acc, product) => {
            if (product.Link.includes('_1')) {
                if (!acc[product.Cor]) {
                    acc[product.Cor] = {
                        idProduto: product.idProduto,
                        Link: product.Link,
                        Cor: product.Cor,
                        Tamanhos: [],
                        Banners: []
                    };
                }


                acc[product.Cor].Tamanhos.push({
                    idProduto: product.idProduto,
                    Tamanho: product.Tamanho,
                    Disponivel: product.Disponivel
                });
            }
            return acc;
        }, {});

        const resultArray = Object.values(groupedProducts);
        const idProdutoAlvo = idProduto
        setProdutosAgrupados(resultArray);

        if (resultArray.length > 0) {

            const resultadoDataSizes = resultArray.filter(item => {
                return item.Tamanhos.some(tamanho => parseInt(tamanho.idProduto) === parseInt(idProdutoAlvo));
            });
            let SizesFilltrado = resultadoDataSizes[0]

            setSizes(SizesFilltrado.Tamanhos);
            setColorsSelect(SizesFilltrado.Cor)
            setBordaAtiva(SizesFilltrado.idProduto)
            setSelectedColor(SizesFilltrado);

        }
    };
    
    const handleColorSelect = (color) => {
        setIsLoadingBanner(true)
        setSelectedSize()
        setBordaAtiva(color.idProduto)
        setIdProduto(color.idProduto);
        setSelectedColor(color);
        setTimeout(() => {
            setIsLoadingBanner(false)
        }, 1900);


    };
    const handleSizeClick = (sizes) => {
        setIsLoadingBanner(true)
        setSelectedSize(sizes.Tamanho);
        setIdProduto(sizes.idProduto)
        setTimeout(() => {
            setIsLoadingBanner(false)
        }, 1900);
    };
    return (
        <>

            <section className="conteudo_interno conteudo_padrao container produtos-detalhe">
                <div className="produtos-detalhe--wrapper">
                    {!isLoading ? (
                        <>
                            <div className="sect-header">
                                <h4 className="titulo h2">{translations.Product?.Producto} - {product.Descricao}</h4>
                                <hr />
                            </div>

                            <div className="produtos-detalhe--conteudo">
                                <div className="carrossel_produtos">
                                    {isLoadingBanner ? <Loader short="false" /> :
                                        <>
                                            {banners &&
                                                <Slider {...settings}>
                                                    {banners?.map((banner, index) => (
                                                        <div className="carrossel_produtos" key={index}>
                                                            <HaveLink banner={banner} />
                                                        </div>
                                                    ))}
                                                </Slider>
                                            }
                                        </>
                                    }

                                </div>
                                <div className="produtos-detalhe--body">
                                    <h4 className="titulo h4">{product.Descricao}</h4>
                                    <p className="produtos-detalhe--codigo">{translations.Product?.Codigo}: {product.CodigoProduto.substring(3)}</p>
                                    <p className="produtos-detalhe--codigo">{translations.Product?.PN}: {product.EAN}</p>

                                    {!login.ApenasVisualizacao &&
                                        <div className="produtos-detalhe--info">
                                            <div className="misc">
                                                <p>{translations.Product?.Embalaje}</p>
                                                <p>{product.Embalagem}</p>
                                            </div>
                                            <div className="misc">
                                                <p>{translations.Product?.Cantidad_caja}</p>
                                                <p>{product.UnidadesPorCaixa}</p>
                                            </div>
                                            <div className="misc">
                                            {product.PossuiPrecoPromocional
                                        ?
                                        <>
                                            <p className="produtos-detalhe--valor-total"><b>{translations.Product?.Total_Caja}: {translations.Invoices?.De} <strike>$ {formatMoney(product.Preco, 2, '.', ',')}</strike> {translations.Product?.por} $ {formatMoney(product.PrecoPromocional, 2, '.', ',')}</b></p>
                                        </>
                                        :
                                        <>
                                            {!login.ApenasVisualizacao ?
                                                <>
                                                    <p className="produtos-detalhe--valor-total"><b>{translations.Product?.Total_Caja} $ {formatMoney((product.PrecoUnitario *  product.UnidadesPorCaixa), 2, '.', ',')}</b></p>
                                                </>
                                                :
                                                <>
                                                    <p className="produtos-detalhe--valor-total"><b>{translations.Product?.Total_Caja} $ {formatMoney((product.PrecoUnitario *  product.UnidadesPorCaixa), 2, '.', ',')}</b></p>
                                                </>
                                            }


                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {login.ApenasVisualizacao &&
                                        <h6 className="card-item--codigo">
                                            {translations.ProductCard?.Precio_Publico}
                                        </h6>
                                    }
                                    {!login.ApenasVisualizacao &&
                                        <h6 className="card-item--codigo">
                                            {translations.Product?.Precio_Lista}
                                        </h6>
                                    }
                                    {product.PossuiPrecoPromocional
                                        ?
                                        <>
                                            <p className="produtos-detalhe--valor--de"><strike>$ {formatMoney(product.PrecoUnitario, 2, '.', ',')}</strike> <small> {translations.Product?.unidad}</small></p>
                                            <p className="produtos-detalhe--valor--por">$ {formatMoney(product.PrecoPromocionalUnitario, 2, '.', ',')} <small>{translations.Product?.unidad}</small></p>
                                        </>
                                        :
                                        <>
                                            {!login.ApenasVisualizacao ?
                                                <>
                                                    <p className="produtos-detalhe--valor">$ {formatMoney(product.PrecoUnitario, 2, '.', ',')} 
                                                        <small>{translations.Product?.unidad}</small></p><p className="produtos-detalhe--valor-total"></p>
                                                </>
                                                :
                                                <>
                                                    <p className="produtos-detalhe--valor">$ {formatMoney(product.ValorTabela, 2, '.', ',')} <small>{translations.Product?.unidad}</small></p><p className="produtos-detalhe--valor-total"></p>
                                                </>
                                            }


                                        </>
                                    }

                                    {!login.ApenasVisualizacao ? <div className="produtos-detalhe--estoque">{product.Estoque > 0 ? `${product.Estoque} disponibles` : <span className="badge badge-black-white">{translations.ProductCard?.Producto_Disponible}</span>}</div> : ''}
                                    {/* {!login.ApenasVisualizacao ? <div className="produtos-detalhe--estoque">{product.Estoque > 0 ? `disponibles` : <span className="badge badge-black-white">Producto no disponible</span>}</div>: ''}                                     */}



                                    <hr />
                                    {product.Estoque > 0 ? '' : !login.ApenasVisualizacao &&
                                        <div style={{ flex: 1, alignItems: 'flex-end', maxWidth: '50%', marginTop: '30px' }}>
                                            <button
                                                type="button"
                                                name="btn-avise"
                                                className="avise--btn btn border rouded-0"
                                                onClick={showModal}
                                            >Notificarme</button>
                                        </div>
                                    }

                                    {product.Estoque > 0 && !login.ApenasVisualizacao &&
                                        <form className="form-adicionar" style={{ marginBottom: 10 }}>
                                            <button type="button" name="btn-item" className="btn form-adicionar--btn" onClick={decrement}>-</button>
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={quantity}
                                                className="card-item--counter"
                                                onChange={handleChange}
                                                maxLength={4}
                                            />
                                            <button type="button" name="btn-item" className="btn form-adicionar--btn" onClick={increment}>+</button>
                                            <button
                                                type="button"
                                                onClick={handleAddToCart}
                                                class={`btn btn--sm btn--roxo btn--bold ${adding && 'active'}`}
                                                id="botaoRoxoPrincipal"
                                            >
                                                {adding
                                                    ? <><div className="spinner-border spinner-border-sm text-light"></div>{translations.ProductCard?.Anadiendo}</>
                                                    : translations.ProductCard?.Agregar
                                                }
                                            </button>
                                        </form>
                                    }
                                    <div className='container-colors'>

                                        {produtosAgrupados?.map((agrupados, index) => (
                                            !agrupados.hidden && (
                                                <img
                                                    alt='Cores disponíveis'
                                                    src={agrupados.Link}
                                                    key={index}
                                                    style={{ border: agrupados.idProduto === bordaAtiva ? '2px solid #00aeef' : 'none' }}
                                                    onClick={() => handleColorSelect(agrupados)}
                                                />
                                            )
                                        ))}
                                        {colorsSelect.length > 0 && <p className="titulo h4">{translations.ProductCard?.Color}: <span>{colorsSelect}</span></p>}
                                        {sizes && (
                                            <>
                                                {sizes.length > 0 && <p className="titulo h4">{translations.ProductCard?.Talla}: <span>{selecteSize}</span></p>}
                                                <div className='container-sizes'>
                                                    {sizes.map((size, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => handleSizeClick(size)}
                                                            style={{
                                                                border: size.Tamanho === selecteSize ? '2px solid #00aeef' : 'none',
                                                                opacity: size.Disponivel ? 1 : 0.5, // Deixa o botão opaco se o tamanho não estiver disponível
                                                                position: 'relative', // Necessário para posicionar o "x" no canto direito

                                                            }}

                                                        >
                                                            {size.Tamanho}
                                                            {!size.Disponivel && (
                                                                <span style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    right: '10px',
                                                                    transform: 'translateY(-50%)',
                                                                    color: 'red'
                                                                }}>x</span>
                                                            )}
                                                        </button>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Loader short="false" />
                    )}

                    {product.LinkImagemDescritivo ?
                        <>
                            <hr />
                            <h4 className="titulo h2">Descritivo</h4>
                            <div className='produto-descritivo banner-container flex row center-center m-auto full-view'>

                                {product.LinkImagemDescritivo ?
                                    <img src={product.LinkImagemDescritivo} alt="Imagen no cargada" />
                                    :
                                    <img src="https://cdn.blink.com.br/Arquivos/sem-foto.png" alt="Imagen no cargada" />
                                }
                            </div>
                            <hr />
                        </>
                        :
                        ''
                    }

                    <h4 className="titulo h4 mais-produtos-titulo">{translations.Cart?.Productos_increibles}</h4>
                    <div className="cards-container">
                        <div className="overflow-auto">
                            <div className="cards cards-produto mais-produtos-4-cards cards-produto-width-4">
                                {!isLoadingSuggestion ? (
                                    productsSuggestion.map(product =>
                                        <ProductCard
                                            key={`product-detail-card-${product.idProduto}`}
                                            product={product}
                                            origin={arrOrigin['pagina_produto_incrementar']}
                                        />
                                    )
                                ) : (
                                    <Loader short="false" />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="sect-footer align-items-start">
                        <button className="btn btn--cinza btn--block btn-full btn--bold"
                            onClick={() => { history.goBack(); }}>{translations.InvoiceDetail?.Regresar}</button>
                    </div>
                </div>
            </section>


            <Modal
                show={isOpen}
                className="modal modal-boleto"
                onHide={hideModal}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
            >
                <Modal.Header>
                    <div className="modal-header_container">
                        <h2 className="titulo h2">Notificarme sobre producto no disponible</h2>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                        <FaTimes color="#00aeef" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="pt-3">
                        <form className="input-group">
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleEmail}
                                className="form-control py-0"
                                placeholder='Ingresar Email'
                            />
                            <button type="submit" className="btn form-control col-md-3" style={{ backgroundColor: '#00aeef', color: 'white' }} onClick={handleEmailSubmit}>
                                Notificarme
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}





const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Product); 